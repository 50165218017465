import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Banner from '@solid-ui-blocks/Hero/Block03'
import Content from '@solid-ui-blocks/Content/Block02'
import CallToAction from '@solid-ui-blocks/CallToAction/Block02'
import WhyChooseUs from '@solid-ui-blocks/Features/Block06'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '../_theme'

const ServicesConcreteGrinding = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Concrete Grinding Melbourne – Concrete Grinders' />

      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='4' />

      <Banner content={content['banner']} />
      <Divider space='4' />

      <Content content={content['services']} />
      <Divider space='3' />

      <WhyChooseUs content={content['why-choose-us']} />
      <Divider space='3' />

      <CallToAction content={content['cta']} />
      <Divider space='5' />

      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageServices01BlockContent {
    allBlockContent(
      filter: { page: { in: ["site/concrete-grinding", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServicesConcreteGrinding
